import React from "react";

export const Customers = () => {
  return (
    <div className="contact section__padding bg__whitesmoke">
      <div className="container">
        <h2 className="section__title text__center">Tərəfdaşlarımız</h2>
        <p className="para__text text__center"> Tərəfdaşlıq üçün mail ünvanımıza <h3>tepat_94@mail.ru</h3> müraciət edə bilərsiniz.</p>
      </div>
    </div>
  );
};

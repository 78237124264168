import React from "react";

const Vacation = () => {
  return (
    <div className="contact section__padding bg__whitesmoke">
      <div className="container">
        <h2 className="section__title text__center">Vakansiyalar</h2>
        <p className="para__text text__center">
          Hazırda aktiv vakansiyamız mövcud deyildir....
        </p>
      </div>
    </div>
  );
};

export default Vacation;

import React from "react";
import Skills from "../components/Skills/Skills";
import Work from "../components/Work/Work";
import "../components/Header/Header.css";
import Services from "../components/Services/Services";
import NavbarHead from "../components/Navbar/NavbarHead.jsx";
import Contact from "../components/Contact/Contact";
import Stats from "../components/Stats/Stats";
import AboutUs from "../components/AboutUs/AboutUs";
import Testimonials from "../components/Testimonials/Testimonials";

function HomePage() {
  return (
    <div>
      <NavbarHead />
      {/* <Stats/> */}
      <AboutUs />

      <Testimonials/>
      <Services />
     <Stats/>
      <Work />
      <Skills />
      <Contact />

     
    </div>
  );
}

export default HomePage;

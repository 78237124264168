import React from "react";

const PhotoUnder = () => {
  return (
    <div className="container">
            <div className="header__content text__center text__light flex flex__center">
        <div className="header__content--left"></div>
        <div className="header__content--right">
          <h1 className="header__title fw__6">Tepat.az</h1>
          <p className="para__text">30 ildən çoxdur ki, xidmətinizdəyik</p>
          <a href="/contact" className="btn btn__blue">
            bizimlə əlaqə
          </a>
        </div>
      </div>
    </div>
  );
};

export default PhotoUnder;

import React from "react";

const HistoryCompany = () => {
  return (
    <div className="about section__padding">
      <div className="container">
        <div className="about__content text__center">
          <h2 className="section__title text__cap">Biz kimik</h2>
          </div>
         
          <table class="table">
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Rəsmi adı:</th>
                <td>"TEPAT" AÇIQ SƏHMDAR CƏMİYYƏTİ</td>
              </tr>
              <tr>
                <th scope="row">VÖEN:</th>
                <td>7300007541</td>
              </tr>

              <tr>
                <th scope="row">Təşkilati-hüquqi forması:</th>
                <td>AÇIQ SƏHMDAR CƏMİYYƏTİ</td>
              </tr>
              <tr>
                <th scope="row">Dövlət qeydiyyatına alındığı tarix:</th>
                <td>2004-06-04</td>
              </tr>
              <tr></tr>
            </tbody>
          </table> 
         
         <br />
        
          <p className="para__text text__grey">
               Şirkətimiz 30 ildən çoxdur ki, “Aİ-92” markalı benzin və “Dizel”
            yanacağının satışını həyata keçirir. Müasir standartlara cavab verən
            yeni modul tipli YDM məntəqələrimiz vardır. Məntəqələrimizdə nağdsız satış üçün
            pos-terminallar da fəaliyyət göstərir, həmçinin “Aİ-95” premium
            markalı benzinin satışını həyata keçirir. Hazırda “Lukoil” brendi ilə
            fəaliyyət göstərən YDM-lərdə müştərilər üçün bir sıra üstünlüklər, o
            cümlədən yanacağın həcm dəqiqliyinin yoxlanılması imkanı mövcuddur.
          </p>
      
      </div>
    </div>
  );
};

export default HistoryCompany;

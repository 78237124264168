import React from "react";
import Tepat_KapitalDeyisilik_2023 from "../assets/document_files/Tepat_KapitalDeyisilik_2023.pdf";
import Tepat_AduitReyi_2023 from "../assets/document_files/Tepat_AduitReyi_2023.pdf";
import Tepat_Balans_2023 from "../assets/document_files/Tepat_Balans_2023.pdf";
import Tepat_MenfeetVeZerer_2023 from "../assets/document_files/Tepat_MenfeetVeZerer_2023.pdf";
import Tepat_PulVesaithesabati_2023 from "../assets/document_files/Tepat_PulVesaithesabati_2023.pdf";

export const FinanceReport = () => {
  
  const onButtonClickKapital = () => {
    const KapitalDeyisilik_2023 = Tepat_KapitalDeyisilik_2023;

    const link = document.createElement("a");
  
    link.href = KapitalDeyisilik_2023;
    link.download = "Tepa_KapitalDeyisilik_2023.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onButtonClickAudit = () => {
    const AduitReyi_2023 = Tepat_AduitReyi_2023;
    
    const linkAudit = document.createElement("a");
    
    linkAudit.href = AduitReyi_2023;
    linkAudit.download = "Tepat_AduitReyi_2023.pdf"; // specify the filename
    document.body.appendChild(linkAudit);
    linkAudit.click();
    document.body.removeChild(linkAudit);
  };

  const onButtonClickBalans = () => {
    const Balans_2023 = Tepat_Balans_2023;
    const link = document.createElement("a");

    link.href = Balans_2023;
    link.download = "Tepat_Balans_2023.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onButtonClickMenfeet = () => {

    const MenfeetVeZerer_2023 = Tepat_MenfeetVeZerer_2023;

    
    const link = document.createElement("a");
    
    link.href = MenfeetVeZerer_2023;
    link.download = "Tepat_MenfeetVeZerer_2023.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onButtonClickPul = () => {

    const PulVesaithesabati_2023 = Tepat_PulVesaithesabati_2023;
    
    const link = document.createElement("a");
    
    link.href = PulVesaithesabati_2023;
    link.download = "Tepat_PulVesaithesabati_2023.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  return (
    <div className="container mt-4">
    <div className="contact section__padding">
    <h1 className="section__title text__center">Maliyyə hesabatları</h1>

    <center className="mt-4">
        <h3>
          <p class="fw-bold">2023-cü ilin Audit rəyi</p>
        </h3>
        <button className="text__blue" onClick={onButtonClickAudit} color="red">
          Hesabatı yüklə
        </button>
      </center>

      <center className="mt-4">
        <h3>
          <p class="fw-bold">2023-cü ilin mənfəət zərər haqqında hesabatı</p>
        </h3>
        <button className="text__blue" onClick={onButtonClickMenfeet} color="red">
          Hesabatı yüklə
        </button>
      </center>

      <center className="mt-4">
        <h3>
          <p class="fw-bold">2023-cü ilin maliyyə balansı</p>
        </h3>
        <button className="text__blue" onClick={onButtonClickBalans} color="red">
          Hesabatı yüklə
        </button>
      </center>
      <center className="mt-4">
        <h3>
          <p class="fw-bold">2023-cü ilin pul vəsaitləri haqqında </p>
        </h3>
        <button className="text__blue" onClick={onButtonClickPul} color="red">
          Hesabatı yüklə
        </button>
      </center>

      <center className="mt-4 mb-4">
        <h3>
          <p class="fw-bold">2023-cü ilin Kapitalda dəyişikliklər haqqında</p>
        </h3>
        <button className="text__blue" onClick={onButtonClickKapital} color="red">
          Hesabatı yüklə
        </button>
      </center>

<br />






</div>



    </div>
  );
};

import "./App.css";
import Services from "./components/Services/Services";
import {Route, Routes } from "react-router-dom";
import HistoryCompany from "./Pages/HistoryCompany";
import HomePage from "./Pages/HomePage";
import { Customers } from "./Pages/Customers";
import { FinanceReport } from "./Pages/FinanceReport";
import Vacation from "./Pages/Vacation";
import Navbar from "./components/Navbar/Navbar";
import AboutCompany from "./Pages/AboutCompany";
import Footer from "./components/Footer/Footer";
import NoPage from "./Pages/NoPage";

function App() {
  return (
    <div className="holder">

      
      <Navbar/>
    
     
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/about" element={<HistoryCompany />} />
          <Route path="/services" element={<Services/>} />
          <Route path="/customers" element={<Customers/>} />
          <Route path="/financereport" element={<FinanceReport/>} />
          <Route path="/contact" element={<AboutCompany/>} />
          <Route path="/vacation" element={<Vacation/>} />
          <Route path="/*" element={<NoPage/>} />
        </Routes>

        <Footer />

    </div>
  );
}

export default App;

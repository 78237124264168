import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer section__padding bg__dark">
      <div className="container">
        <div className="footer__content grid text__light text__center">
          <div className="footer__content--item">
            <a href="/about" className="footer__navlink">
              Tepat Açıq Səhmdar Cəmiyyəti
            </a>
            <hr />
            <p className="para__text">&copy; 2024-cü il by Tepat ASC</p>
          </div>

          <div className="footer__content--item">
            <a href="mailto:someone@gmail.com">tepat_94@mail.ru</a>
            <br/>
            <span>+9942232555798</span>
          </div>

          <div className="footer__content--item">
            <h3 className="footer__title">Ümumi</h3>
            <hr />
            <ul className="footer__links">
              <li>
                <a href="#">Haqqımızda</a>
              </li>
              <li>
                <a href="#">Xidmətlər</a>
              </li>
              <li>
                <a href="#">Vakansiya</a>
              </li>
            </ul>
          </div>

          <div className="footer__content--item">
            <h3 className="footer__title">Xidmətlər</h3>
            <hr />
            <ul className="footer__links">
              <li>
                <a href="#">Xidmətlər</a>
              </li>
              {/* <li><a href = "#">Contact</a></li>
                            <li><a href = "#">Legals</a></li> */}
            </ul>
          </div>

          <div className="footer__content--item">
            <h3 className="footer__title">Hesabatlılıq</h3>
            <hr />
            <ul className="footer__links">
              <li>
                <a href="#">Hesabatlar</a>
              </li>
              {/* <li><a href = "#">Twitter</a></li>
                            <li><a href = "#">Instagram</a></li> */}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

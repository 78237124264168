import header_bg from "../assets/images/header-bg.jpg";
import banner26 from "../assets/images/banner26.jpg";
import signature from "../assets/images/signature.png";
import parking_86279 from "../assets/images/parking_86279.png";
import refuel from "../assets/images/refuel.png";
import carwash from "../assets/images/car-wash.png";
import oilicon from "../assets/images/oil_icon.png";


import Yanacaq_doldurma from "../assets/images/Yanacaq_doldurma.jpg";
import parking from "../assets/images/parking.jpg";
import Avto_yuma from "../assets/images/Avto_yuma.jpg";
import yag_deyisme from "../assets/images/yag_deyisme.jpg";
import picture1 from "../assets/images/picture1.jpg";




export default{
    header_bg,
    Yanacaq_doldurma,
    parking,
    Avto_yuma,
    yag_deyisme,
    banner26,
    signature,
    picture1,
    parking_86279,
    refuel,
    carwash,
    oilicon

};
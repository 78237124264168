import React, { useState } from "react";

import "./Navbar.css";
import {FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";


const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className="navbar bg__dark">
      <div className="container flex">
        <div className="navbar__collapse">
          <ul className="navbar__nav">
            <li className="nav__item">
              <div className="brand__and__toggler flex">
                <a
                  href="/"
                  className="nav__link text__upper fw__6 text__blue nav__active"
                >
             <h1>Tepat.az</h1>     
                </a>
              </div>
            </li>
            <li className="nav__item">
              <Link
                to="about"
                className="nav__link text__upper fw_6 text__light"
              >
                haqqımızda
                </Link>
            </li>
            <li className="nav__item">

            <Link className="nav__link text__upper fw_6 text__light" to="/financereport">hesabatlar</Link>
              
            </li>
            <li className="nav__item">
              <Link
                to="services"
                className="nav__link text__upper fw_6 text__light"
              >
                Xidmətlərimiz
                </Link>
            </li>
            <li className="nav__item">
              <Link
                to="customers"
                className="nav__link text__upper fw_6 text__light"
              >
                tərəfdaşlıq
                </Link>
            </li>
            <li className="nav__item">
              <Link
                to="vacation"
                className="nav__link text__upper fw_6 text__light"
              >
                vakansiya
                </Link>
            </li>
            <li className="nav__item">
              <Link
                to="contact"
                className="nav__link text__upper fw_6 text__light"
              >
                əlaqə
                </Link>
            </li>
          </ul>
        </div>

        {toggleMenu && (
          <div className="navbar__smallscreen">
            <button
              type="button"
              className="navbar__close--btn text__light"
              onClick={() => setToggleMenu(false)}
            >
              <FaTimes size={32} />
            </button>
            <ul className="navbar__nav--smallscreen text__light">
              <li className="nav__item">
                <a
                  href="/"
                  className="nav__link text__upper fw__6 nav__active text__light"
                >
                  Tepat.az
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="HistoryCompany"
                  className="nav__link text__upper fw__6 text__light"
                >
                  haqqımızda
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#about"
                  className="nav__link text__upper fw__6 text__light"
                >
                  iş təcrübəsi
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#about"
                  className="nav__link text__upper fw__6 text__light"
                >
                  process
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#about"
                  className="nav__link text__upper fw__6 text__light"
                >
                  services
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#about"
                  className="nav__link text__upper fw__6 text__light"
                >
                  vakansiya
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="HistoryCompany"
                  className="nav__link text__upper fw__6 text__light"
                >
                  əlaqə
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

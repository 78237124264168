import React from "react";

const AboutCompany = () => {
  return (
    <div className="contact section__padding bg__whitesmoke">
      <div className="container">
        <h2 className="section__title text__center">Ünvan və əlaqə</h2>

        <div className="contact__content">
          <table class="table">
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Ünvan:</th>
                <td>AZ6022, TOVUZ RAYONU, DONDAR QUŞÇU K,	</td>
              </tr>
              <tr>
                <th scope="row">Mail:</th>
                <td> tepat_94@mail.ru , office@tepat.az</td>
              </tr>

              <tr>
                <th scope="row">Mobil nömrə:</th>
                <td>+994 2232 55 57 98</td>
              </tr>

            
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;

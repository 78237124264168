import React from "react";
import images from "../../constants/images";
import "./AboutUs.css";

const AboutUs = () => (
  <div className="about section__padding">
    <div className="container">
      <div className="about__content text__center">
        <h2 className="section__title text__cap">haqqımızda</h2>
    
        <p className="para__text text__grey">
          Şirkətimiz 30 ildən çoxdur ki, “Aİ-92” markalı benzin və “Dizel”
          yanacağının satışını həyata keçirilir. Müasir standartlara cavab verən
          yeni modul tipli YDM məntəqələrimiz vardır. Nağdsız satış üçün
          pos-terminallar da fəaliyyət göstərir, həmçinin “Aİ-95” premium
          markalı benzinin satışını həyata keçirir. Hazırda “Lukoil” brendi ilə
          fəaliyyət göstərən YDM-lərdə müştərilər üçün bir sıra üstünlüklər, o
          cümlədən yanacağın həcm dəqiqliyinin yoxlanılması imkanı mövcuddur.
        </p>
        <img src={images.signature} alt="" />
      </div>
    </div>
  </div>
);

export default AboutUs;

import images from "./images";

// skills - Uğurlarımız
const skills = [
  {
    title: "Müştəri məmnuniyyəti",
    value: "100%",
  },
  {
    title: "Keyfiyyət",
    value: "100%",
  },
  {
    title: "Tərəfdaşlıq",
    value: "100%",
  },
  {
    title: "Peşəkar personal",
    value: "100%",
  },
];

// stats
const stats = [
  {
    img: `${images.icon_briefcase}`,
    value: "",
    title: "",
  },
  {
    img: `${images.icon_briefcase}`,
    value: "",
    title: "",
  },
  {
    img: `${images.icon_briefcase}`,
    value: "",
    title: "",
  },
  {
    img: `${images.icon_briefcase}`,
    value: "",
    title: "",
  },
];

// works - iş vaxtı şəkilləri
const works = [
  {
    img: `${images.picture1}`,
  },
  {
    img: `${images.parking}`,
  },
  {
    img: `${images.Avto_yuma}`,
  },
  {
    img: `${images.yag_deyisme}`,
  },
];

// services
const services = [
  {
    img: `${images.refuel}`,
    title: "Yanacaq doldurma",
    text: "3 yanacaq doldurma məntəqəmiz fəaliyyət göstərir",
  },
  {
    img: `${images.parking_86279}`,
    title: "Parkinq",
    text: "Hətda ərazidə parkinq xidmətidə mövcuddur",
  },
  {
    img: `${images.carwash}`,
    title: "Avto yuma",
    text: "Avto yuma məntəqəsi saat 8.00 dən saat 23.00 dət fəaliyyət göstərir. Gəlmədən öncə randevu alın ki vaxta qənayət edəsiz.",
  },
  {
    img: `${images.oilicon}`,
    title: "Yağ dəyişmə",
    text: "Avtomobilinizin yağ dəyimə xidməti",
  },
];

// testimonials
const testimonials = [
  {
    name: "Tərəfdaşlıq",
    text: "Şirkətimiz etibarlı tərəfdaş kimi yerini və rolunu möhkəmləndirir.",
  },
  {
    name: "Müştəri məmnuniyyəti",
    text: "Şirkət satışlarının artmasına təşviq edən əsas amillərdən biri müştəri məmnuniyyətidir",
  },
  {
    name: "Peşəkar personal",
    text: "Müştərilərimizi yüksək səviyyədə xidmətlə təmin etmək məqsədilə, onlara 24×7 rejimində xidmət göstəririk",
  },
  {
    name: "Ödəmə üsulları",
    text: "Həm nağd həm, POS terminalla ödəniş imkanı",
  },
];

export default { skills, stats, works, services, testimonials };

import React from "react";
import images from "../../constants/images";
import "./Navbar.css";
import PhotoUnder from "../Header/PhotoUnder";

const NavbarHead = () => {
  
  return (
    <>
      <div
        className=""
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url(${images.header_bg}) center/cover no-repeat`,
        }}
      >
        <PhotoUnder />
      </div>
    </>
  );
};

export default NavbarHead;
